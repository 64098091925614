'use strict'
const path = require('path')
const opentype = require('opentype.js')
const charPreset = require('./char-preset')

const fontPath = path.join(__dirname, '../fonts/Comismsh.ttf')
const ascender = null
const descender = null
const font = null

const options = {
  width: 150,
  height: 50,
  noise: 1,
  color: false,
  background: '',
  size: 4,
  ignoreChars: '',
  fontSize: 56,
  charPreset,
  font,
  ascender,
  descender,
}

const loadFont = filepath => {
  return new Promise(function(resolve, reject) {
    fontLoader(filepath)
      .then(font => {
        options.font = font
        options.ascender = font.ascender
        options.descender = font.descender
        resolve('Ok')
      })
      .catch(err => {
        reject(err)
      })
  })
}

async function fontLoader(url) {
  return await new Promise((resolve, reject) =>
    opentype.load(url, (err, font) => (err ? reject(err) : resolve(font)))
  )
}

module.exports = {
  options,
  loadFont,
}
