<template>
  <div v-html="image"></div>
</template>
<script>
export default {
  name: 'SvgCaptcha',
  props: {
    refreshCaptcha: String,
  },
  data() {
    return {
      image: null,
      svgCaptcha: null,
    }
  },
  mounted() {
    this.svgCaptcha = require('@/core/plugins/svg-captcha')
    this.svgCaptcha
      .loadFont('/media/fonts/Comismsh.ttf')
      .then((res) => {
        this.createCaptcha()
      })
      .catch((err) => {
        console.log(err)
        this.image = null
      })
  },
  methods: {
    createCaptcha() {
      const captcha = this.svgCaptcha.create({
        noise: 2,
        background: '#F3F6F9',
      })
      this.image = captcha.data
      this.$emit('set-captcha', captcha.text)
    },
  },
  watch: {
    refreshCaptcha: function (val) {
      this.createCaptcha()
    },
  },
}
</script>
