<template>
  <div class="login-form login-signin d-flex flex-column justify-content-between">
    <div class="mb-10 mt-lg-25">
      <img src="/image/side-logo.png"
           class="mb-10"
           alt="logo" />
      <h3 class="font-size-h1 text-bold">
        {{ $ml.get(`welcomeTo${this.appName()}`) }}
      </h3>
      <p class="text-muted font-size-secondary font-weight-semi-bold">
        {{ $ml.get('alreadyHaveAnAccount') }}
        <router-link class="text-bold font-size-3 ml-2"
                     :to="{ name: 'login' }">
          {{ $ml.get('signIn') }}
        </router-link>
      </p>
    </div>

    <!--begin::Signup-->
    <div class="login-form login-signin mt-10 mb-10">
      <!--begin::Form-->
      <b-form class="form"
              @submit.stop.prevent="onSubmit">
        <b-form-group id="fullname-group"
                      :label="$ml.get('fullname')"
                      label-for="fullname-input">
          <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                        id="fullname-input"
                        name="fullname"
                        v-model.trim="$v.form.fullname.$model"
                        :state="validateState('fullname')"
                        aria-describedby="fullname-input-live-feedback"
                        :placeholder="$ml.get('fullname')"></b-form-input>

          <b-form-invalid-feedback id="fullname-input-live-feedback">
            {{ $ml.get('fullnameIsRequired') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="email-input-group"
                      :label="$ml.get('emailAddress')"
                      label-for="email-input">
          <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                        id="email-input"
                        name="email"
                        v-model.trim="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-input-live-feedback"
                        :placeholder="$ml.get('emailAddress')"></b-form-input>

          <b-form-invalid-feedback id="email-input-live-feedback">
            {{ $ml.get('emailIsRequiredInfoText') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="password-input-group"
                      :label="$ml.get('password')"
                      label-for="password-input">
          <b-form-input class="form-control form-control-solid h-auto py-5 px-6"
                        :type="form.showPassword ?'text':'password'"
                        id="password-input"
                        name="password-input"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        @input="changePassword"
                        aria-describedby="password-input-live-feedback"
                        :placeholder="$ml.get('password')"></b-form-input>

          <i :class="['far', {'fa-eye-slash' : form.showPassword,'fa-eye' : !form.showPassword }]"
             @click="form.showPassword = !form.showPassword"></i>

          <b-form-invalid-feedback id="password-input-live-feedback">
            {{ this.form.password ==='' ? $ml.get('passwordIsRequired')   : $ml.get('passwordIsNotValid')  }}
          </b-form-invalid-feedback>
        </b-form-group>

        <PasswordStrengthViewer :form="form"
                                :validPassword="validPassword"></PasswordStrengthViewer>

        <b-input-group class="h-auto p-0 mb-5">
          <div class="d-flex mt-11 mb-3 bg-light">
            <b-input-group-prepend @click="refreshCaptcha()"
                                   class="captchaArea">
              <SvgCaptcha @set-captcha="setCaptcha"
                          :refreshCaptcha="refreshCaptchaStr"></SvgCaptcha>
            </b-input-group-prepend>
          </div>

          <b-form-group class="form-control border-0 h-auto"
                        id="captcha-input-group"
                        :label="$ml.get('captcha')"
                        label-for="captcha-input">
            <b-form-input class="form-control-solid h-auto py-5 px-6"
                          type="text"
                          id="captcha-input"
                          v-model="$v.form.captcha.$model"
                          :placeholder="$ml.get('captcha')"
                          :state="validateState('captcha')"></b-form-input>
          </b-form-group>
        </b-input-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap flex-center p-0">
          <button type="submit"
                  ref="kt_login_signup_submit"
                  class="btn btn-custom w-100 text-bold d-block px-9 my-3">
            {{ $ml.get('createFreeAccount') }}
          </button>
        </div>
        <div class="or"><span>{{$ml.get('or')}}</span></div>
        <div class="socials mt-10">
          <a :href="`${appLoginUrl}sign-in/github`"
             class="socialButtonCollapse">
            <div class="socialButton github">
              <i class="fab fa-github mr-2"></i>
              {{$ml.get('loginWith').replace('{0}','GitHub')}}
            </div>
          </a>
          <a :href="`${appLoginUrl}sign-in/gitlab`"
             class="socialButtonCollapse">
            <div class="socialButton gitlab">
              <i class="fab fa-gitlab mr-2"></i>
              {{$ml.get('loginWith').replace('{0}','GitLab')}}
            </div>
          </a>
        </div>
        <div class="socials">
          <a :href="`${appLoginUrl}sign-in/google`"
             class="socialButtonCollapse">
            <div class="socialButton google">
              <i class="fab fa-google mr-2"></i>
              {{$ml.get('loginWith').replace('{0}','Google')}}
            </div>
          </a>
          <a :href="`${appLoginUrl}sign-in/linkedin`"
             class="socialButtonCollapse">
            <div class="socialButton linkedin">
              <i class="fab fa-linkedin mr-2"></i>
              {{$ml.get('loginWith').replace('{0}','Linkedin')}}
            </div>
          </a>
        </div>
        <!-- <div class="registerClickInfo"
             ref="registerInfo"
             v-html="$ml.get('loginClickInfo')"></div> -->
        <div class="registerClickInfo"
             ref="registerInfo"></div>

        <div v-if="$ml.current=='tr'"
             class="registerClickInfo"
             ref="registerInfo">Butona tıklayarak <a href="javascript:;"
             v-b-modal.modal-terms>Hizmet Şartlarımızı</a> ve <a href="javascript:;"
             v-b-modal.modal-privacy>Gizlilik Politikamızı</a> kabul etmiş olursunuz.</div>

        <div v-else-if="$ml.current=='en'"
             class="registerClickInfo"
             ref="registerInfo">By clicking the button you agree to our <a href="javascript:;"
             id="terms"
             v-b-modal.modal-terms>Terms of Service</a> and <a href="javascript:;"
             id="privacy"
             v-b-modal.modal-privacy>Privacy Policy</a>.</div>

        <div v-else-if="$ml.current=='nl'"
             class="registerClickInfo"
             ref="registerInfo">Door op de knop te klikken, gaat u akkoord met onze <a href="javascript:;"
             v-b-modal.modal-terms>Servicevoorwaarden</a> en <a href="javascript:;"
             v-b-modal.modal-privacy>Privacybeleid</a></div>

        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->

    <AuthFooter />
  </div>
</template>
  
<script>
import { mapState } from 'vuex'
import {
  REGISTER,
  VERIFY_REGISTERED_USER,
} from '@/core/services/store/auth.module'
import { LOGIN, LOGOUT, LOGOUT_FORCE } from '@/core/services/store/auth.module'
import ApiService from '@/core/services/api.service'

import { validationMixin } from 'vuelidate'
import { email, required, minLength, sameAs } from 'vuelidate/lib/validators'

import PasswordStrengthViewer from '@/components/PasswordStrengthViewer'
import SvgCaptcha from '@/components/SvgCaptcha'
import AuthFooter from '@/components/auth/AuthFooter'

const basicValidate = require('basic-validate-js')

export default {
  name: 'register',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        fullname: '',
        email: '',
        password: '',
        captcha: '',
        showPassword: false,
      },
      validPassword: {
        strongNumber: 0,
        isMinOneUpper: false,
        isMinOneLower: false,
        isMinEightChar: false,
        isMinOneNumber: false,
        isMinOneSpecial: false,
      },
      captcha: '',
      refreshCaptchaStr: '',
    }
  },
  validations: {
    form: {
      fullname: {
        required,
        minLength: minLength(3),
        isFullname(fullname) {
          return /^[a-zA-Z_ğüşıöçĞÜŞİÖÇ]+(?:\s[a-zA-Z_ğüşıöçĞÜŞİÖÇ]+)+$/gm.test(
            fullname
          ) // check full name
        },
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        strongPassword(password) {
          return (
            /[0-9]/g.test(password) && // checks is contain num
            /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(password) && // checks is contain special
            /[A-Z]/g.test(password) && // checks is contain upper
            /[a-z]/g.test(password) // checks is contain lower
          )
        },
      },
      captcha: {
        required,
        sameAsCaptcha: sameAs(function () {
          return this.captcha
        }),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    changePassword() {
      // password control regex
      let isContainNumReg = /[0-9]/g
      let isContainSpecialReg = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
      let isContainUpper = /[A-Z]/g
      let isContainLower = /[a-z]/g

      // set valid
      if (this.form.password.length >= 8) {
        this.validPassword.isMinEightChar || this.validPassword.strongNumber++
        this.validPassword.isMinEightChar = true
      } else {
        !this.validPassword.isMinEightChar || this.validPassword.strongNumber--
        this.validPassword.isMinEightChar = false
      }

      if (basicValidate.patternControl(this.form.password, isContainNumReg)) {
        this.validPassword.isMinOneNumber || this.validPassword.strongNumber++
        this.validPassword.isMinOneNumber = true
      } else {
        !this.validPassword.isMinOneNumber || this.validPassword.strongNumber--
        this.validPassword.isMinOneNumber = false
      }

      if (
        basicValidate.patternControl(this.form.password, isContainSpecialReg)
      ) {
        this.validPassword.isMinOneSpecial || this.validPassword.strongNumber++
        this.validPassword.isMinOneSpecial = true
      } else {
        !this.validPassword.isMinOneSpecial || this.validPassword.strongNumber--
        this.validPassword.isMinOneSpecial = false
      }

      if (basicValidate.patternControl(this.form.password, isContainUpper)) {
        this.validPassword.isMinOneUpper || this.validPassword.strongNumber++
        this.validPassword.isMinOneUpper = true
      } else {
        !this.validPassword.isMinOneUpper || this.validPassword.strongNumber--
        this.validPassword.isMinOneUpper = false
      }

      if (basicValidate.patternControl(this.form.password, isContainLower)) {
        this.validPassword.isMinOneLower || this.validPassword.strongNumber++
        this.validPassword.isMinOneLower = true
      } else {
        !this.validPassword.isMinOneLower || this.validPassword.strongNumber--
        this.validPassword.isMinOneLower = false
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      const fullname = this.$v.form.fullname.$model
      const email = this.$v.form.email.$model
      const password = this.$v.form.password.$model
      const utmCode =
        this.$route.query && this.$route.query.utm
          ? this.$route.query.utm
          : null

      // Separate name and surname
      let name = fullname.split(' ').slice(0, -1).join(' ')
      let surname = fullname.split(' ').slice(-1).join(' ')

      // clear existing errors
      this.$store.dispatch(LOGOUT)
      this.$store.dispatch(LOGOUT_FORCE)
      ApiService.removeHeader()

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signup_submit']
      this.$spinnerLoader.addSpinnerLoader(submitButton)

      const params = {
        email: email,
        password: password,
        name: name,
        surname: surname,
        default_locale: this.getLocale(),
      }

      if (utmCode) {
        params.utm = utmCode
      }

      // send register request
      this.$store
        .dispatch(REGISTER, params)
        .then((data) => {
          this.verifyUser(email, name, surname)
        })
        .catch((err) => {
          if (err == 'Error') {
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          }

          this.$spinnerLoader.removeSpinnerLoader(submitButton)
        })
    },
    verifyUser(email, name, surname) {
      const submitButton = this.$refs['kt_login_signup_submit']

      this.$store
        .dispatch(VERIFY_REGISTERED_USER, email)
        .then((res) => {
          this.$spinnerLoader.removeSpinnerLoader(submitButton)

          if (res.is_email_validated) {
            this.$generateToast(this, 'danger', this.$ml.get('pleaseLogin'))
            this.$router.push({ name: 'login' })
          } else {
            this.$generateToast(
              this,
              'success',
              this.$ml.get('yourAccountCreated')
            )
            setTimeout(() => {
              this.$store
                .dispatch(LOGIN, {
                  data: {
                    token_type: 'Empty',
                    access_token: 'No Token',
                    account: {
                      name: 'No Company',
                      user: { fullname: `${name} ${surname}`, email: email },
                    },
                  },
                })
                .then((res) => {
                  this.$router.push({ name: 'general' })
                })
            }, 1500)
          }
        })
        .catch((err) => {
          this.$generateToast(
            this,
            'danger',
            this.$ml.get('anErrorHasOccurred')
          )
          this.$spinnerLoader.removeSpinnerLoader(submitButton)
        })
    },
    setCaptcha(text) {
      this.captcha = text
    },
    refreshCaptcha() {
      this.refreshCaptchaStr = new Date().getTime().toString()
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
    getUrlParams(params) {
      let url = new URL(window.location.href)
      return url.searchParams.get(params)
    },
    getLocale() {
      let _locale = this.getUrlParams('locale')
      if (_locale == 'tr' || _locale == 'en' || _locale == 'nl') return _locale

      return process.env.VUE_APP_DEFAULT_LANGUAGE
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    appLoginUrl() {
      return process.env.VUE_APP_LOGIN_URL
    },
  },
  mounted() {
    var self = this

    this.$refs['registerInfo'].addEventListener('click', function (event) {
      // event.preventDefault()
      if (event.target.id == 'privacy') {
        self.$bvModal.show('modal-privacy')
      } else if (event.target.id == 'terms') {
        self.$bvModal.show('modal-terms')
      }
    })
  },
  components: {
    PasswordStrengthViewer,
    SvgCaptcha,
    AuthFooter,
  },
}
</script>

<style lang="scss">
.login-form {
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
  .captchaArea {
    cursor: pointer;
  }
  #password-input-group {
    position: relative;
    i {
      position: absolute;
      cursor: pointer;
      color: #758196;
      top: 50px;
      right: 40px;
    }
  }
  .form-group label {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #2f3044 !important;
  }
  .btn-custom {
    background: #eef1f5;
    border-radius: 6px;
    height: 48px;
  }
  .socials {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    .socialButtonCollapse {
      width: 48%;
      margin-right: 2%;
      .socialButton {
        border: 1px solid #333333;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 20px;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
          margin-left: 2%;
        }
        &:hover {
          opacity: 0.8;
        }
        &.github {
          color: #333333;
          border-color: #333333;
          i {
            color: #333333;
          }
        }
        &.gitlab {
          color: #d15036;
          border-color: #d15036;
          i {
            color: #d15036;
          }
        }
        &.google {
          color: #4285f4;
          border-color: #4285f4;
          i {
            color: #4285f4;
          }
        }
        &.linkedin {
          color: #2867b2;
          border-color: #2867b2;
          i {
            color: #2867b2;
          }
        }
      }
    }
  }
  .or {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 0.1rem;
    color: #b5bbc6;
    margin: 20px auto;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #b5bbc6;
    span {
      padding: 0 10px;
      background: #fff;
    }
  }
  .registerClickInfo {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #364866;
    a {
      text-decoration: underline;
      color: #364866;
    }
  }
}
</style>
