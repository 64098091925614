<template>
  <div class="password-view">
    <!-- First area levels -->
    <div :class="[
        'level level-1',
        {
          green: form.password != '' && validPassword.strongNumber >= 1,
        },
      ]"></div>
    <div :class="[
        'level level-2',
        {
          green: form.password != '' && validPassword.strongNumber >= 2,
        },
      ]"></div>
    <div :class="[
        'level level-3',
        {
          green: form.password != '' && validPassword.strongNumber >= 3,
        },
      ]"></div>
    <div :class="[
        'level level-4',
        {
          green: form.password != '' && validPassword.strongNumber >= 4,
        },
      ]"></div>
    <div :class="[
        'level level-5',
        {
          green: form.password != '' && validPassword.strongNumber >= 5,
        },
      ]"></div>
    <div class="scope-text"
         v-show="form.password == ''">{{ $ml.get('score') }}</div>
    <div class="scope-text red"
         v-show="
        form.password != '' &&
          (validPassword.strongNumber >= 0 && validPassword.strongNumber <= 2)
      ">
      {{ $ml.get('fair') }}
    </div>
    <div class="scope-text orange"
         v-show="form.password != '' && (validPassword.strongNumber >= 3 && validPassword.strongNumber <= 4)">
      {{ $ml.get('good') }}
    </div>
    <div class="scope-text green"
         v-show="form.password != '' && validPassword.strongNumber == 5">
      {{ $ml.get('veryStrong') }}
    </div>

    <!-- Second area requirements -->
    <div class="requirements">
      <!-- This line 8 characters minimum -->
      <div :class="['item eight-chars', { active: validPassword.isMinEightChar }]">
        <div :class="[
            'circle eight-chars-circle',
            { green: validPassword.isMinEightChar },
            { red: form.password.length != '' },
          ]"></div>
        <span class="eight-chars-text">
          {{ $ml.get('eightCharactersMinimum') }}
        </span>
      </div>

      <!-- This line uppsercase for 1 characters minimum -->
      <div :class="['item eight-chars', { active: validPassword.isMinOneUpper }]">
        <div :class="[
            'circle eight-chars-circle',
            { green: validPassword.isMinOneUpper },
            { red: form.password.length != '' },
          ]"></div>
        <span class="eight-chars-text">
          {{ $ml.get('oneUpper') }}
        </span>
      </div>

      <!-- This line lowercase for 1 characters minimum -->
      <div :class="['item eight-chars', { active: validPassword.isMinOneLower }]">
        <div :class="[
            'circle eight-chars-circle',
            { green: validPassword.isMinOneLower },
            { red: form.password.length != '' },
          ]"></div>
        <span class="eight-chars-text">
          {{ $ml.get('oneLower') }}
        </span>
      </div>

      <!-- This line one number -->
      <div :class="['item one-number', { active: validPassword.isMinOneNumber }]">
        <div :class="[
            'circle one-number-circle',
            { green: validPassword.isMinOneNumber },
            { red: form.password.length != '' },
          ]"></div>
        <span class="one-number-text ">
          {{ $ml.get('oneNumber') }}
        </span>
      </div>

      <!-- This line one special character -->
      <div :class="[
          'item one-special-char',
          { active: validPassword.isMinOneSpecial },
        ]">
        <div :class="[
            'circle one-special-char-circle',
            { green: validPassword.isMinOneSpecial },
            { red: form.password.length != '' },
          ]"></div>
        <span class="one-special-char-text">
          {{ $ml.get('oneSpecialCharacter') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrengthViewer',
  props: {
    validPassword: Object,
    form: Object,
  },
}
</script>

<style lang="scss" scoped>
.password-view {
  margin-bottom: 20px;

  .level {
    float: left;
    width: calc(20% - 5px);
    height: 3px;
    background-color: #e1e4e8;
    margin-right: 5px;

    &.level-5 {
      width: calc(20%);
      margin-right: 0;
    }

    &.red {
      background-color: #f03d3d;
    }

    &.orange {
      background-color: #ffad0d;
    }

    &.green {
      background-color: #0bb07b;
    }
  }

  .scope-text {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    text-align: right;
    color: #e1e4e8;
    margin-top: 6px;

    &.red {
      color: #f03d3d;
    }

    &.orange {
      color: #ffad0d;
    }

    &.green {
      color: #0bb07b;
    }
  }

  .requirements {
    color: #c9ced6;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    line-height: 18px;

    .item {
      display: flex;
      align-items: center;

      .circle {
        background-color: #e1e4e8;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        margin-right: 8px;

        &.red {
          background-color: #f03d3d;
        }

        &.orange {
          background-color: #ffad0d;
        }

        &.green {
          background-color: #0bb07b;
        }
      }
    }

    .active {
      color: #0a1f44;
    }
  }
}
</style>
